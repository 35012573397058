import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import { Heading } from '@web/atoms';
import {
  CaloriesContainer,
  RecipeText,
  AnalysisText,
  Row,
  Border,
  ImageContainer,
  RecipeContainer,
  Container,
  AnalysisContainer,
  ImageWrapper,
  ButtonContainer,
  ButtonWrapper,
} from './styles.js';

export const RecipeOverview = ({
  title,
  description,
  buttonText,
  urlTarget,
  image,
  imageBackgroundColor,
  imagePosition = 'right',
  recipeBackgroundColor,
  nutritionalInfoBackgroundColor,
  caloriesValue,
  nutritionalInfo,
}) => {
  const imageFirst = imagePosition === 'left';
  return (
    <Container backgroundColor={imageBackgroundColor}>
      <RecipeContainer backgroundColor={recipeBackgroundColor}>
        <Heading {...title} />
        <RecipeText>{description}</RecipeText>
        <ButtonContainer>
          <ButtonWrapper backgroundColor={recipeBackgroundColor}>
            <LinkButton href={urlTarget}>{buttonText}</LinkButton>
          </ButtonWrapper>
        </ButtonContainer>
      </RecipeContainer>

      <CaloriesContainer
        imageFirst={imageFirst}
        backgroundColor={nutritionalInfoBackgroundColor}
      >
        <Row>
          <Heading
            headingLevel="h6"
            typography="heading6"
            headingText="Calories (kcal/kg)"
            verticalPadding={1}
          />
          <AnalysisText>{caloriesValue}</AnalysisText>
        </Row>
        <Border />
        <Heading
          headingLevel="h6"
          typography="heading6"
          headingText="Guaranteed Analysis"
          verticalPadding={1}
        />
        <AnalysisContainer>
          {nutritionalInfo.map(item => (
            <Row key={item.label}>
              <AnalysisText>{item.label}</AnalysisText>
              <AnalysisText>{item.value}</AnalysisText>
            </Row>
          ))}
        </AnalysisContainer>
      </CaloriesContainer>
      <ImageContainer
        imageFirst={imageFirst}
        backgroundColor={imageBackgroundColor}
      >
        <ImageWrapper>
          {image && (
            <ResponsiveImage
              src={image.src}
              alt={image.alt}
              height={image.height}
              width={image.width}
            />
          )}
        </ImageWrapper>
      </ImageContainer>
    </Container>
  );
};

RecipeOverview.displayName = 'RecipeOverview';

RecipeOverview.propTypes = {
  title: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  image: PropTypes.object,
  imageBackgroundColor: PropTypes.string,
  imagePosition: PropTypes.oneOf(['left', 'right']),
  caloriesValue: PropTypes.number.isRequired,
  recipeBackgroundColor: PropTypes.string,
  nutritionalInfoBackgroundColor: PropTypes.string,
  urlTarget: PropTypes.string.isRequired,
  nutritionalInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
