import React from 'react';
import dryBeefIngredientsImage from '../images/product-photos/pack-with-ingredients/pack-ingredients-drybeef.jpg';
import dryChickenIngredientsImage from '../images/product-photos/pack-with-ingredients/pack-ingredients-drychicken.jpg';
import JerkyTreatBeefSweetPotatoImage from '../images/our-food/addons/JerkyTreats-BeefSweetPotato.jpg';
import JerkyTreatChickenAppleImage from '../images/our-food/addons/JerkyTreats-ChickenApple.jpg';
import SweetPotatoTreatImage from '../images/our-food/addons/StripTreats-SweetPotato.jpg';
import ZoomiesImage from '../images/our-food/addons/Supplements-Zoomies.png';
import ChillPillsImage from '../images/our-food/addons/Supplements-ChillPills.png';
import BellyRubsImage from '../images/our-food/addons/Supplements-BellyRubs.png';
import { ADDON_SKUS } from './constants';

const beefModal = {
  value: 'beef',
  headline: 'Beef',
  fullIngredients:
    'Beef, Peas, Sweet Potatoes, Potatoes, Carrots, Beef Kidney, Beef Liver, Spinach, Blueberries, Tricalcium Phosphate, Chia Seeds, Salmon Oil, Salt, Zinc Gluconate, Rosemary, Copper Amino Acid Chelate, Vitamin E Supplement,  Ferrous Sulfate, Choline Bitartrate, Manganese Amino Acid Chelate, Thiamine Hydrochloride, Vitamin D3,  Pyridoxine Hydrochloride (Vitamin B6), Riboflavin (Vitamin B2), Potassium Iodide.',
  nutritionalInfo: [
    {
      label: 'Protein',
      value: 'min 9%',
    },
    {
      label: 'Fat',
      value: 'min 7%',
    },
    {
      label: 'Fiber',
      value: 'max 2%',
    },
    {
      label: 'Moisture',
      value: 'max 70%',
    },
  ],
  body: `Our Beef Recipe is formulated to meet the nutritional levels established by the AAFCO Dog Food Nutrient Profiles for All Life Stages, including growth of large size Dogs (>70 pounds or more as an adult). Calorie content (calculated): 1540 kcal ME/kg.`,
  image: {
    src: 'https://a.storyblok.com/f/145952/1920x2563/f82a6cf80c/pack-ingredients-freshbeef.jpg',
    alt: 'beef',
    width: 420,
    height: 500,
  },
};

const chickenModal = {
  value: 'chicken',
  headline: 'Chicken',
  fullIngredients:
    'Chicken, Carrots, Peas, Rice, Chicken Liver, Potatoes, Spinach, Blueberries, Eggs, Tricalcium Phosphate, Chia Seeds, Salmon Oil, Salt, Zinc Gluconate, Rosemary, Copper Amino Acid Chelate, Vitamin E Supplement, Ferrous Sulfate, Choline Bitartrate, Manganese Amino Acid Chelate, Thiamine Hydrochloride, Vitamin D3,  Pyridoxine Hydrochloride (Vitamin B6), Riboflavin (Vitamin B2), Potassium Iodide.',
  nutritionalInfo: [
    {
      label: 'Protein',
      value: 'min 10%',
    },
    {
      label: 'Fat',
      value: 'min 3%',
    },
    {
      label: 'Fiber',
      value: 'max 2%',
    },
    {
      label: 'Moisture',
      value: 'max 73%',
    },
  ],
  body: 'Our Chicken Recipe is formulated to meet the nutritional levels established by the AAFCO Dog Food Nutrient Profiles for All Life Stages, including growth of large size Dogs (>70 pounds or more as an adult). Calorie content (calculated): 1298 kcal ME/kg.',
  image: {
    src: 'https://a.storyblok.com/f/145952/1920x2563/746aa35eaa/pack-ingredients-freshchicken.jpg',
    alt: 'chicken',
    width: 420,
    height: 500,
  },
};

const turkeyModal = {
  value: 'turkey',
  headline: 'Turkey',
  fullIngredients:
    'Turkey, Kale, Lentils, Carrots, Turkey Liver, Oats, Blueberries, Pumpkin, Tricalcium Phosphate, Chia Seeds, Sunflower Oil, Salt, Salmon Oil, Zinc Gluconate, Ferrous Sulfate, Copper Amino Acid Chelate, Vitamin E Supplement, Manganese Amino Acid Chelate, Choline Bitartrate, Thiamine Mononitrate, Riboflavin (Vitamin B2), Vitamin D3, Pyridoxine Hydrochloride (Vitamin B6), Potassium Iodide.',
  nutritionalInfo: [
    {
      label: 'Protein',
      value: 'min 11%',
    },
    {
      label: 'Fat',
      value: 'min 7%',
    },
    {
      label: 'Fiber',
      value: 'max 2%',
    },
    {
      label: 'Moisture',
      value: 'max 72%',
    },
  ],
  body: 'Our Turkey Recipe is formulated to meet the nutritional levels established by the AAFCO Dog Food Nutrient Profiles for All Life Stages, including growth of large size Dogs (>70 pounds or more as an adult). Calorie content (calculated): 1390 kcal ME/kg.',
  image: {
    src: 'https://a.storyblok.com/f/145952/1920x2563/cd6514d766/pack-ingredients-freshturkey.jpg',
    alt: 'turkey',
    width: 420,
    height: 500,
  },
};

const lambModal = {
  value: 'lamb',
  headline: 'Lamb',
  fullIngredients:
    'Lamb, Butternut Squash, Lamb Liver, Chickpeas, Kale, Rice, Cranberries, Green Beans, Potatoes, Tricalcium Phosphate, Chia Seeds, Salmon Oil, Salt, Zinc Gluconate, Taurine, Vitamin E Supplement, Ferrous Sulfate, Calcium Pantothenate, Thiamine Hydrochloride, Manganese Amino Acid Chelate, Vitamin D3, Pyridoxine Hydrochloride (Vitamin B6), Potassium Iodide, Folic Acid.',
  nutritionalInfo: [
    {
      label: 'Protein',
      value: 'min 10%',
    },
    {
      label: 'Fat',
      value: 'min 7%',
    },
    {
      label: 'Fiber',
      value: 'max 2%',
    },
    {
      label: 'Moisture',
      value: 'max 74%',
    },
  ],
  body: 'Our Lamb Recipe is formulated to meet the nutritional levels established by the AAFCO Dog Food Nutrient Profiles for All Life Stages, including growth of large size Dogs (>70 pounds or more as an adult). Calorie content (calculated): 1804 kcal ME/kg.',
  image: {
    src: 'https://a.storyblok.com/f/145952/1920x2563/8737fdbcde/pack-ingredients-freshlamb.jpg',
    alt: 'lamb',
    width: 420,
    height: 500,
  },
};

const porkModal = {
  value: 'pork',
  headline: 'Pork',
  fullIngredients:
    'Pork, pork livers, butternut squash, rice, sweet potatoes, oats, peas, carrots, spinach, apples, tricalcium phosphate, salmon oil, salt, taurine, zinc gluconate, vitamin E supplement, ferrous sulphate, copper amino acid chelate, manganese amino acid chelate, cholecalciferol (vitamin D3) supplement, riboflavin (vitamin B2), thiamine hydrochloride (vitamin B1), pyridoxine hydrochloride (vitamin B6), potassium iodide.',
  nutritionalInfo: [
    {
      label: 'Protein',
      value: 'min 9%',
    },
    {
      label: 'Fat',
      value: 'min 7%',
    },
    {
      label: 'Fiber',
      value: 'max 2%',
    },
    {
      label: 'Moisture',
      value: 'max 72%',
    },
  ],
  body: 'Our Pork Recipe is formulated to meet the nutritional levels established by the AAFCO Dog Food Nutrient Profiles for All Life Stages, including growth of large size Dogs (>70 pounds or more as an adult). Calorie content (fed): 1540 kcal ME/kg.',
  image: {
    src: 'https://a.storyblok.com/f/89894/1920x2563/7548e02995/onboarding-fresh-pork-recipe-view-more.jpg',
    alt: 'pork',
    width: 1920,
    height: 2563,
  },
};

export const dryModalsContent = [
  {
    value: 'baked_beef',
    headline: 'Baked Beef',
    fullIngredients:
      'Beef, Oats, Chickpeas, Pea Flour, Beef Livers, Sweet Potatoes, Carrots, Coconut Oil, Tricalcium Phosphate, Dicalcium Phosphate, Apples, Salt, Spinach, Calcium Carbonate, Fish Oil, Rosemary Extract, Vitamin E Supplement, Choline Bitartrate, Zinc Gluconate, Potassium Iodide, Ferrous Bisglycinate Chelate, Beta Carotene (Vitamin A), Riboflavin (Vitamin B2), Copper Amino Acid Chelate, Niacin, Cholecalciferol (Vitamin D3), Cyanocobalamin (Vitamin B12), Pyridoxine Hydrochloride (Vitamin B6), Calcium Pantothenate (Vitamin B5),  Thiamine Hydrochloride (Vitamin B1), Folic Acid, Potassium Citrate.',
    nutritionalInfo: [
      {
        label: '% Protein (min.)',
        value: '23%',
      },
      {
        label: '% Fat (min.)',
        value: '13.5%',
      },
      {
        label: '% Fiber (max)',
        value: '4%',
      },
      {
        label: '% Moisture (max.)',
        value: '10%',
      },
    ],
    body: `
      Our Baked Bites Beef Recipe is formulated to meet the nutritional
      levels established by the AAFCO Dog Food Nutrient Profiles for All
      Life Stages, including growth of large size Dogs (>70 pounds or
      more as an adult). Calorie content (calculated): 3850 kcal ME/kg.
    `,
    image: {
      src: dryBeefIngredientsImage.src,
      alt: 'beef',
      width: 420,
      height: 500,
    },
  },
  {
    value: 'baked_chicken',
    headline: 'Baked Chicken',
    fullIngredients:
      'Chicken, Oats, Chickpeas, Pea Flour, Chicken Livers, Whole Dried Eggs, Carrots, Sweet Potatoes, Tricalcium Phosphate, Dicalcium Phosphate, Apples, Salt, Spinach, Calcium Carbonate, Fish Oil, Sunflower Oil, Rosemary Extract, Choline Bitartrate, Vitamin E Supplement, Zinc Gluconate, Potassium Iodide, Ferrous Bisglycinate Chelate, Copper Amino Acid Chelate, Beta Carotene (Vitamin A), Riboflavin (Vitamin B2), Niacin, Cholecalciferol (Vitamin D3), Cyanocobalamin (Vitamin B12), Pyridoxine Hydrochloride (Vitamin B6), Calcium Pantothenate (Vitamin B5), Thiamine Hydrochloride (Vitamin B1), Potassium Citrate, Folic Acid.',
    nutritionalInfo: [
      {
        label: '% Protein (min.)',
        value: '24%',
      },
      {
        label: '% Fat (min.)',
        value: '14.5%',
      },
      {
        label: '% Fiber (max.)',
        value: '4%',
      },
      {
        label: '% Moisture (max.)',
        value: '10%',
      },
    ],
    body: `
      Our Baked Bites Chicken Recipe is formulated to meet the
      nutritional levels established by the AAFCO Dog Food Nutrient
      Profiles for All Life Stages, including growth of large size
      Dogs (>70 pounds or more as an adult). Calorie content
      (calculated): 3850 kcal ME/kg.
    `,
    image: {
      src: dryChickenIngredientsImage.src,
      alt: 'chicken',
      width: 420,
      height: 500,
    },
  },
];

export const freshModalsContent = [
  beefModal,
  chickenModal,
  turkeyModal,
  lambModal,
  porkModal,
];

export const supplementsModalDropdownValues = {
  dropdowns: [
    {
      name: 'quantity',
      label: 'Quantity',
      options: [
        { label: '1 Jar', value: 1 },
        { label: '2 Jars', value: 2 },
        { label: '3 Jars', value: 3 },
        { label: '4 Jars', value: 4 },
        { label: '5 Jars', value: 5 },
        { label: '6 Jars', value: 6 },
      ],
    },
    {
      name: 'frequency',
      label: 'Frequency',
      options: [
        { label: 'Every box', value: 1 },
        { label: 'Every other box', value: 2 },
      ],
    },
  ],
  addButtonLabel: 'Add',
  removeButtonLabel: 'Remove',
  doneButtonLabel: 'Done',
};

export const treatsModalDropdownValues = {
  dropdowns: [
    {
      name: 'quantity',
      label: 'Quantity',
      options: [
        { label: '1 Bag', value: 1 },
        { label: '2 Bags', value: 2 },
        { label: '3 Bags', value: 3 },
        { label: '4 Bags', value: 4 },
        { label: '5 Bags', value: 5 },
        { label: '6 Bags', value: 6 },
      ],
    },
    {
      name: 'frequency',
      label: 'Frequency',
      options: [
        { label: 'Every box', value: 1 },
        { label: 'Every other box', value: 2 },
        { label: 'Next box only', value: 1 },
      ],
    },
  ],
  addButtonLabel: 'Add',
  removeButtonLabel: 'Remove',
  doneButtonLabel: 'Done',
};

export const treatsModalsContent = [
  {
    value: 'Chicken and Apple Jerky Treats',
    title: 'Chicken and Apple Recipe',
    productName: 'Chicken and Apple Jerky',
    sku: ADDON_SKUS.chickenjerky,
    subtitle: 'Jerky treats',
    details:
      'Our 100% all-natural jerky strips are made with simple, human-grade ingredients to keep snack time real and exciting. Our Chicken and Apple Recipe is a tasty and healthy way to reward and treat your pup regularly.',
    nutritionalInformation: [
      {
        label: 'Protein minimum',
        value: '26%',
      },
      {
        label: 'Fat minimum',
        value: '16%',
      },
      {
        label: 'Fiber maximum',
        value: '2%',
      },
      {
        label: 'Moisture maximum',
        value: '25%',
      },
    ],
    fullIngredients:
      'Chicken, Oats, Coconut Glycerin, Apple, Sea Salt, Celery Powder',
    size: '1 bag (5oz)',
    pricingInfo: {
      price: '12',
      discountedPrice: '9',
      saveText: '(Save 25%)',
    },
    serving:
      "Feed as a treat. Treats should not make up more than 10% of your dog's daily calorie intake.",
    image: {
      src: JerkyTreatChickenAppleImage.src,
      alt: 'Chicken and apple jerky treats',
      width: 500,
      height: 500,
    },
    images: [
      {
        src: JerkyTreatChickenAppleImage.src,
        alt: 'Chicken and apple jerky treats',
        width: 500,
        height: 500,
      },
    ],
    dropdownValues: treatsModalDropdownValues,
    productType: 'TR',
    defaultValues: {
      quantity: 1,
      frequency: 1,
    },
  },
  {
    value: 'Beef and Sweet Potato Jerky Treats',
    title: 'Beef and Sweet Potato Recipe',
    productName: 'Beef and Sweet Potato Jerky',
    sku: ADDON_SKUS.beefjerky,
    subtitle: 'Jerky treats',
    details:
      'Our 100% all-natural jerky strips are made with simple, human-grade ingredients to keep snack time real and exciting. Our Beef and Sweet Potato Recipe is a hearty and healthy way to reward and treat your pup regularly.',
    nutritionalInformation: [
      {
        label: 'Protein minimum',
        value: '27%',
      },
      {
        label: 'Fat minimum',
        value: '16%',
      },
      {
        label: 'Fiber maximum',
        value: '2%',
      },
      {
        label: 'Moisture maximum',
        value: '21%',
      },
    ],
    fullIngredients:
      'Beef, Oats, Coconut Glycerin, Sweet Potato, Sea Salt, Celery Powder',
    size: '1 bag (5oz)',
    pricingInfo: {
      price: '12',
      discountedPrice: '9',
      saveText: '(Save 25%)',
    },
    serving:
      "Feed as a treat. Treats should not make up more than 10% of your dog's daily calorie intake.",
    image: {
      src: JerkyTreatBeefSweetPotatoImage.src,
      alt: 'Beef and sweet potato jerky treats',
      width: 500,
      height: 500,
    },
    dropdownValues: treatsModalDropdownValues,
    productType: 'TR',
    defaultValues: {
      quantity: 1,
      frequency: 1,
    },
  },
  {
    value: 'Sweet Potato Slices',
    title: 'Sweet Potato',
    productName: 'Sweet Potato Slices',
    sku: ADDON_SKUS.sweetpotatoes,
    subtitle: 'Slices',
    details:
      'Our all-natural sweet potato slices keep snack time simple for your pup. No artificial flavors or preservatives mean just real ingredients for their just rewards, so you can treat your pup more healthfully, more often.',
    nutritionalInformation: [
      {
        label: 'Protein minimum',
        value: '7.5%',
      },
      {
        label: 'Fat minimum',
        value: '1.5%',
      },
      {
        label: 'Fiber maximum',
        value: '9%',
      },
      {
        label: 'Moisture maximum',
        value: '25%',
      },
    ],
    fullIngredients: 'Sweet potato',
    size: '1 bag (5oz)',
    pricingInfo: {
      price: '12',
      discountedPrice: '9',
      saveText: '(Save 25%)',
    },
    serving:
      "Feed as a treat. Treats should not make up more than 10% of your dog's daily calorie intake.",
    image: {
      src: SweetPotatoTreatImage.src,
      alt: 'Sweet potato strip treats',
      width: 500,
      height: 500,
    },
    mobileImage: {
      src: SweetPotatoTreatImage.src,
      alt: 'Sweet potato strip treats',
      width: 250,
      height: 250,
    },
    dropdownValues: treatsModalDropdownValues,
    productType: 'TR',
    defaultValues: {
      quantity: 1,
      frequency: 1,
    },
  },
];

export const supplementsModalsContent = [
  {
    value: 'Chill Pills Calming Supplement',
    title: 'Chill Pills',
    productName: 'Chill Pills',
    sku: ADDON_SKUS.chillpills,
    subtitle: 'Calming formula',
    details:
      "The ingredients in our Chill Pills calming formula are blended to work with a dog's biochemistry in order to help soothe and calm naturally.",
    nutritionalInformation: [
      {
        label: 'Organic Hemp Seed Oil',
        value: '100mg',
      },
      {
        label: 'Ginkgo Biloba Leaf',
        value: '75mg',
      },
      {
        label: 'Passion Flower',
        value: '50mg',
      },
      {
        label: 'Melatonin',
        value: '1mg',
      },
    ],
    fullIngredients: (
      <p>
        <strong>Active Ingredients:</strong> Hemp Seed Oil, Ginko Biloba Leaf,
        Passion Flower, Melatonin
        <br />
        <strong>Inactive Ingredients:</strong> Brewer’s Yeast, Citric Acid (as
        preservative), Flaxseed Meal, Vegetable Glycerin, Palm Fruit Oil
        (Organic, RSPO), Peanut Butter and Banana Flavor (non-allergenic)
        Sunflower Lecithin, Tapioca Starch.
      </p>
    ),
    size: '1 jar (60 chews)',
    pricingInfo: {
      price: '32',
      discountedPrice: '16',
      saveText: '(Save 50%)',
    },
    serving:
      'Do not exceed the recommended daily amounts. Suggested Use: Up to 30 lbs. 1 soft chew/day | 31-60 lbs. 2 soft chews/day | 61-90 lbs. 3 soft chews/day | 91 lbs. and Above 4 soft chews/day | This product is intended for intermittent or supplemental use only.',
    image: {
      src: ChillPillsImage.src,
      alt: 'Chill Pills calming supplement',
      width: 500,
      height: 500,
    },
    dropdownValues: supplementsModalDropdownValues,
    productType: 'SP',
    defaultValues: {
      quantity: 1,
      frequency: 1,
    },
  },
  {
    value: 'Zoomies Hip and Joint Supplement',
    title: 'Zoomies',
    sku: ADDON_SKUS.zoomies,
    productName: 'Zoomies',
    subtitle: 'Hip and joint formula',
    details:
      'Our Zoomies hip and joint formula helps reduce inflammation and joint pain in dogs of all ages.',
    nutritionalInformation: [
      {
        label: 'Glucosamine HCl (from Shellfish)',
        value: '450mg',
      },
      {
        label: 'Methylsulfonylmethane (MSM)',
        value: '400mg',
      },
      {
        label: 'Ascorbic Acid (Vitamin C)',
        value: '140mg',
      },
      {
        label: 'Chondroitin Sulfate (Porcine)',
        value: '125mg',
      },
      {
        label: 'D-alpha tocopherol acetate (Vitamin E)',
        value: '25 IU',
      },
      {
        label: 'Kelp Powder',
        value: '25mg',
      },
      {
        label: 'Tumeric',
        value: '25mg',
      },
    ],
    fullIngredients: (
      <p>
        <strong>Active Ingredients:</strong> Glucosamine HCl (from shellfish),
        Methylsulfonylmethane (MSM), Vitamin C, Chondroitin Sulfate (porcine),
        Vitamin E, Kelp Powder, Turmeric (Organic)
        <br />
        <strong>Inactive Ingredients:</strong> Brewer’s Yeast, Citric Acid (as
        preservative), Flaxseed Meal, Vegetable Glycerin, Palm Fruit Oil
        (Organic, RSPO), Sunflower Lecithin, Tapioca Flour, Natural Vegetarian
        Turkey Flavor
      </p>
    ),
    size: '1 jar (60 chews)',
    pricingInfo: {
      price: '12',
      discountedPrice: '9',
      saveText: '(Save 25%)',
    },
    serving:
      'Do not exceed the recommended daily amounts. Suggested Use: Up to 30 lbs. 1 soft chew/day | 31-60 lbs. 2 soft chews/day | 61-90 lbs. 3 soft chews/day | 91 lbs. and Above 4 soft chews/day | This product is intended for intermittent or supplemental use only.',
    image: {
      src: ZoomiesImage.src,
      alt: 'Zoomies hip and joint supplement',
      width: 500,
      height: 500,
    },
    dropdownValues: supplementsModalDropdownValues,
    productType: 'SP',
    defaultValues: {
      quantity: 1,
      frequency: 1,
    },
  },
  {
    value: 'Belly Rubs Probiotic Supplement',
    title: 'Belly Rubs',
    productName: 'Belly Rubs',
    sku: ADDON_SKUS.bellyrubs,
    subtitle: 'Probiotic formula',
    details:
      'Our Belly Rubs supplement is scientifically-formulated using five strains of probiotics to help improve digestion and encourage gut health in dogs.',
    nutritionalInformation: [
      {
        label: 'Pumpkin Seed Powder',
        value: '100mg',
      },
      {
        label: 'Papaya Leaf Powder',
        value: '50mg',
      },
      {
        label:
          'Probiotic Blend: Enterococcus faecium, Lactobacillus acidophilus, Lactobacillus casei, Lactococcus lactis, Lactobacillus reuteri',
        value: '1B CFU',
      },
    ],
    fullIngredients: (
      <p>
        <strong>Active Ingredients:</strong> Pumpkin Seed Powder, Papaya Leaf
        Powder, Probiotic Blend (Enterococcus faecium, Lactobacillus,
        Acidophilus, Lactobacillus casei, Lactococcus, Lactis, Lactobacillus
        Reuteri)
        <br />
        <strong>Inactive Ingredients:</strong> Brewer’s Yeast, Citric Acid (as
        preservative), Natural Vegetarian Duck Flavor, Organic Flaxseed Powder,
        Vegetable Glycerin, Oat Flour, Palm Fruit Oil (Organic, RSPO), Sunflower
        Lecithin.', size: '1 jar (60 chews)
      </p>
    ),
    pricingInfo: {
      price: '32',
      discountedPrice: '16',
      saveText: '(Save 50%)',
    },
    serving:
      'Do not exceed the recommended daily amounts. Suggested Use: Up to 30 lbs. 1 soft chew/day | 31-60 lbs. 2 soft chews/day | 61-90 lbs. 3 soft chews/day | 91 lbs. and Above 4 soft chews/day | This product is intended for intermittent or supplemental use only.',
    image: {
      src: BellyRubsImage.src,
      alt: 'Belly Rubs probiotic supplement',
      width: 500,
      height: 500,
    },
    dropdownValues: supplementsModalDropdownValues,
    productType: 'SP',
    defaultValues: {
      quantity: 1,
      frequency: 1,
    },
  },
];
