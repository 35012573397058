import React from 'react';
import * as yup from 'yup';
import { VerticalPanel } from '@web/components';
import { CMSBlok } from '../translation';

export const component = VerticalPanel;

export const schema = yup
  .object({
    backgroundColor: yup.string(),
    fillColor: yup.string(),
    content: yup.array(yup.object()).required(),
    componentHeading: yup.string(),
  })
  .transform(value => ({
    backgroundColor: value.background_color || undefined,
    fillColor: value.fill_color || undefined,
    content: value.content.map(innerContent => {
      if (innerContent.component === 'cta_button') {
        innerContent = { ...innerContent, headerText: value.componentHeading };
        return <CMSBlok key={innerContent._uid} content={innerContent} />;
      } else {
        return <CMSBlok key={innerContent._uid} content={innerContent} />;
      }
    }),
  }));
