'use client';
import styled, { css } from 'styled-components';
import { generateContentIconStyle } from '@ui/icons';

export const Card = styled.div`
  border-radius: 32px;
  border: 2px solid
    ${props =>
      props.checked ? props.theme.colors.secondary.egg : 'transparent'};
  display: inline-flex;
  flex-direction: column;
  user-select: none;
  overflow: hidden; /* prevent overflow on rounded corners */
  position: relative; /* allow absolute positioning of child elements */
  width: ${props => (props.hidden ? '326px' : '343px')};
  background: ${({ theme, unavailable }) =>
    unavailable
      ? theme.colors.background.disabled
      : theme.colors.background.lightest};
  opacity: ${({ $notSelectable }) => ($notSelectable ? 0.5 : 1)};
  color: ${({ theme }) => theme.colors.text.secondary};
  padding-bottom: ${props => props.theme.spacing(15.5)};
`;
export const CardContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  padding: ${props => props.theme.spacing(4)};
`;

export const StyledImage = styled.div`
  border-radius: 16px;
  overflow: hidden;
  object-position: left;
  object-fit: cover;
  height: 112px;
  width: 112px;
  filter: ${props => props.unavailable && 'grayscale(100%) brightness(93%)'};
`;

export const MoreDetails = styled.button`
  position: absolute;
  left: ${props => props.theme.spacing(4)};
  bottom: ${props => props.theme.spacing(8)};
  align-items: center;
  display: flex;
  border: none;
  padding: 0 3px;
  visibility: ${props =>
    props.unavailable || props.disabled ? 'hidden' : 'visible'};
  background-color: transparent;
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.hero.huckleberry};
  text-align: left;
  z-index: 1;

  svg {
    margin-left: ${props => props.theme.spacing(1)};
  }
  &:focus {
    border-radius: ${props => props.theme.spacing(2)};
    outline: 2px solid ${({ theme }) => theme.colors.neutral.darkestFur};
  }
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const Name = styled.h2`
  ${({ theme }) => theme.typography.subheading1}
  ${({ theme }) => theme.fontFamily.base}
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const StrikeThroughPrice = styled.span`
  ${({ theme }) => theme.typography.body}
  ${({ theme }) => theme.fontFamily.base}
  margin-right: ${({ theme }) => theme.spacing(1)};
  text-decoration-line: line-through;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.hero.cranberry};
`;

export const DisplayedPrice = styled.span`
  ${({ theme }) => theme.typography.bodyBold}
  ${({ theme }) => theme.fontFamily.base}
	margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const CardHeader = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(4)};
  margin-bottom: ${props => props.theme.spacing(4)};
`;

export const KeyInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const PricingSymbol = styled.div``;

export const InactiveSymbol = styled.span`
  color: ${props => props.theme.colors.text.tertiary};
`;

export const ActiveSymbol = styled.span`
  color: ${props => props.theme.colors.text.primary};
`;

export const Description = styled.div`
  ${({ theme }) => theme.typography.body}
  p,
  #text {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 200px;
  padding: ${props => props.theme.spacing(4)};
  background: ${props =>
    props.checked
      ? props.theme.colors.secondary.egg
      : props.theme.colors.secondary.chia};
  background: ${props => props.unavailable && 'transparent'};
  min-width: 126px;
  max-width: 250px;
  height: 48px;
  align-items: center;
  ${props =>
    (props.icon || props.checked || props.unavailable) &&
    css`
      gap: ${props.theme.spacing(3)};
    `};

  ${props =>
    props.unavailable &&
    css`
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-between;
    `}
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: ${props => props.theme.spacing(4)};
  bottom: ${props => props.theme.spacing(4)};
  padding: 2px;
  &:focus-within {
    border-radius: ${props => props.theme.spacing(2)};
    outline: 2px solid ${({ theme }) => theme.colors.neutral.darkestFur};
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  &::before {
    ${props =>
      props.checked
        ? generateContentIconStyle('check')
        : props.unavailable
        ? generateContentIconStyle('close')
        : props.icon
        ? generateContentIconStyle(props.icon)
        : ''};
    width: ${props =>
      (props.icon || props.checked || props.unavailable) && '16px'};
    height: ${props =>
      (props.icon || props.checked || props.unavailable) && '16px'};
    background: ${props =>
      props.checked || props.unavailable
        ? props.theme.colors.text.primary
        : props.theme.colors.text.lightest};
  }

  &::after {
    content: '';
    border-radius: 32px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;
export const LabelText = styled.span`
  ${({ theme }) => theme.typography.button}
  text-align: center;
  color: ${({ theme, checked, unavailable }) =>
    checked || unavailable
      ? theme.colors.text.primary
      : theme.colors.text.lightest};
`;

export const StyledCollapsibleMenu = styled.div`
  max-width: 586px;
  border-radius: 32px;
  background-color: ${props => props.theme.colors.neutral.white};
  padding: ${props => props.theme.spacing(6)};
`;

export const PricesContainer = styled.div`
  ${props => props.theme.typography.body}
`;

export const MenuButton = styled.div`
  max-height: 60px;
  display: flex;
  color: ${props => props.theme.colors.text.secondary};
  justify-content: space-between;
  align-items: center;
  ${props => props.theme.typography.button}
  margin-bottom:${props => props.open && props.theme.spacing(6)};
`;

export const ExpandedMenu = styled.div``;

export const BadgesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 0.75rem;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const CalloutBadgeContainer = styled.div``;
