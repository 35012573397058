import styled, { css, keyframes } from 'styled-components';

const marquee = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const marquee2 = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
`;

export const StyledValuesBanner = styled.div`
  background-color: ${props => props.theme.colors.get(props.backgroundColor)};
  overflow-x: hidden;
  padding: 32px 0;
  width: 100%;

  ${props =>
    props.loaded &&
    css`
      ${MarqueeOne} {
        animation: ${marquee} 20s linear infinite;
        opacity: 1;
      }
      ${MarqueeTwo} {
        animation: ${marquee2} 20s linear infinite;
        opacity: 1;
      }
    `}
`;

export const ScrollContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
`;

export const MarqueeOne = styled.div`
  display: inline-block;
  white-space: nowrap;
  opacity: 0;
  transition: 250ms opacity;
`;

export const MarqueeTwo = styled.div`
  display: inline-block;
  white-space: nowrap;
  opacity: 0;
  transition: 250ms opacity;
`;

export const ValuesWrapper = styled.div`
  display: inline-block;
`;

export const ValueList = styled.div`
  display: flex;
`;

export const Value = styled.div`
  color: ${props => props.theme.colors.get(props.fontColor)};
  align-items: center;
  flex: 0 0 auto;
  display: flex;
  padding: 0 36px;
`;

export const IconWrapper = styled.div`
  margin-right: 16px;
  height: 60px;
  width: 60px;
`;

export const Text = styled.span`
  display: block;
  ${props => props.theme.fontFamily.condensed}
  text-transform: uppercase;
  white-space: nowrap;
`;
