import styled from 'styled-components';

export const StyledTrustPilotList = styled.section`
  padding: ${props => props.theme.spacing(2)};
  margin: auto;
  width: 100%;
`;

export const ReviewsContainer = styled.div`
  width: 100%;
`;
