import React from 'react';
import PropTypes from 'prop-types';
import { DividerContainer, InnerDivider } from './styles';

export const Divider = ({ direction = 'vertical', color = 'dark' }) => {
  return (
    <DividerContainer $direction={direction}>
      <InnerDivider $direction={direction} $color={color} />
    </DividerContainer>
  );
};

Divider.displayName = 'Divider';

Divider.propTypes = {
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  color: PropTypes.string,
};
