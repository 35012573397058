import React, { useState } from 'react';
import { TabsContainer, TabList, TabButton, TabContent } from './styles';

export const Tabs = ({ tabs, backgroundColor }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = index => {
    setActiveTabIndex(index);
  };

  return (
    <TabsContainer $backgroundColor={backgroundColor}>
      <TabList>
        {tabs.map((tab, index) => (
          <TabButton
            key={index}
            isActive={index === activeTabIndex}
            onClick={() => handleTabClick(index)}
            $fontColor={tab.fontColor}
          >
            {tab.name}
          </TabButton>
        ))}
      </TabList>
      <TabContent>{tabs[activeTabIndex]?.content}</TabContent>
    </TabsContainer>
  );
};
