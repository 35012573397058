'use client';
import styled from 'styled-components';
import { StyledMuxPlayer } from '../MuxVideo/styles';

export const StyledSwipeHero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${props => props.theme.tablet} {
    display: flex;
    flex-direction: row;
    height: max-content;
  }
`;
export const AssetsContainer = styled.div`
  width: 100%;
  max-height: 320px;
  display: flex;
  background: ${props => props.theme.colors.get(props.assetBgColor)};
  @media ${props => props.theme.tablet} {
    max-height: 610px;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.get(props.contentBgColor)};
  padding-top: ${props => props.theme.spacing(5)};
  padding-bottom: ${props => props.theme.spacing(6)};
  @media ${props => props.theme.tablet} {
    width: 50%;
    padding: 0;
  }
`;

export const Subheadline = styled.p`
  ${props => props.theme.typography.button};
  color: ${props => props.theme.colors.text.secondary};
  padding: ${props => props.theme.spacing(1, 1, 2)};
  @media ${props => props.theme.tablet} {
    padding: 0;
  }
`;

export const Body = styled.div`
  ${props => props.theme.typography.body}
  display: inline;
  padding-bottom: ${props => props.theme.spacing(4)};
  @media ${props => props.theme.tablet} {
    padding-bottom: ${props => props.theme.spacing(7)};
  }
`;

export const BodyImageContainer = styled.div`
  display: none;
  @media ${props => props.theme.tablet} {
    display: block;
    padding: ${props => props.theme.spacing(3, 0, 5)};
  }
`;

export const MuxVideoWrapper = styled.div`
  height: 100%;
  width: 100%;
  ${StyledMuxPlayer} {
    width: auto;
    height: 100%;
  }
`;
