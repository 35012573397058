'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import {
  StyledFeedingTab,
  TabContentContainer,
  TabContentSection,
  SectionHeading,
  SectionBody,
  ButtonContainer,
  AssetWrapper,
  AssetContainer,
  AssetImage,
  AssetText,
  AssetBody,
  AssetHeadline,
} from './styles';

export const FeedingTab = ({ sections, assets, cta }) => {
  return (
    <StyledFeedingTab>
      <TabContentContainer>
        {sections &&
          sections?.map(
            ({ headline = '', headingLevel = 'h4', body }, index) => (
              <TabContentSection key={index}>
                {headline && (
                  <SectionHeading as={headingLevel}>{headline}</SectionHeading>
                )}
                <SectionBody>{body}</SectionBody>
              </TabContentSection>
            ),
          )}
        <AssetWrapper>
          {assets &&
            assets?.map(({ image, headline, body }, index) => (
              <AssetContainer key={index}>
                <AssetImage>
                  <ResponsiveImage height={200} width={200} {...image} />
                </AssetImage>
                <AssetText>
                  <AssetHeadline>{headline}</AssetHeadline>
                  <AssetBody>{body}</AssetBody>
                </AssetText>
              </AssetContainer>
            ))}
        </AssetWrapper>
        {cta && (
          <ButtonContainer>
            <LinkButton
              variant={cta.buttonVariant || 'chia'}
              href={cta.linkTarget}
              horizontalPadding={24}
              fullWidth={true}
            >
              {cta.linkText}
            </LinkButton>
          </ButtonContainer>
        )}
      </TabContentContainer>
    </StyledFeedingTab>
  );
};

FeedingTab.displayName = 'FeedingTab';

FeedingTab.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      headingLevel: PropTypes.string,
      body: PropTypes.object,
    }),
  ),
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.node,
      headline: PropTypes.string,
      body: PropTypes.string,
    }),
  ),
  cta: PropTypes.shape({
    buttonVariant: PropTypes.string,
    linkTarget: PropTypes.string,
    linkText: PropTypes.string,
  }),
};
