import * as yup from 'yup';
import { TrustPilotList } from '@web/components';

export const component = TrustPilotList;

export const schema = yup
  .object({
    template_id: yup.string(),
    business_unit_id: yup.string(),
    locale: yup.string(),
  })
  .transform(({ body, ...value }) => ({
    templateId: value.template_id,
    businessUnitId: value.business_unit_id,
    locale: value.locale,
  }));
