import * as yup from 'yup';
import { DetailsTab } from '@web/components/DetailsTab';
import { responsiveImageFromCMSSchema } from '../schemas';

export const component = DetailsTab;
// Define schema for the tab details
export const schema = yup
  .object({
    headline: yup.string().required(),
    subheadline: yup.string(),
    level: yup.string(),
    images: yup.array(responsiveImageFromCMSSchema).min(2).max(4), // Minimum 2, maximum 4 images
    bullets: yup.array().of(
      yup.object({
        bullet: yup.string().required(),
      }),
    ),
    font_color: yup.string().optional(),
    buttonVariant: yup.string().optional(),
    background_color: yup.string().nullable(),
    linkText: yup.string(),
    linkTarget: yup.string().nullable(),
  })
  .transform(value => ({
    headline: value.headline,
    subheadline: value.subheadline,
    level: value.level,
    linkTarget: value.link_target?.url,
    linkText: value.link_text,
    images: value.images, // Images remain unchanged
    bullets: value.bullets, // Bullets remain unchanged
    fontColor: value.font_color, // Default to black if no font_color is provided
    buttonVariant: value.button_variant, // Default to 'default' if no buttonVariant is provided
    backgroundColor: value.background_color, // Default to white if no background_color is provided
  }));
