import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { Eyebrow } from '@web/atoms';
import { Details } from './Details';
import {
  StyledRecipeInformationSection,
  Header,
  Subheadline,
  Headline,
  Container,
  ImagePanel,
  ImageOuter,
  ImageWrapper,
  DetailsWrapper,
} from './styles.js';

export const RecipeInformationSection = ({
  title,
  image,
  icon = null,
  notice = '',
  description = '',
  details = [],
  headline = '',
  subheadline = '',
  backgroundColor = 'background.base',
  buttonVariant = 'bone',
  link = null,
  eyebrow,
  level,
}) => (
  <StyledRecipeInformationSection backgroundColor={backgroundColor}>
    {eyebrow && <Eyebrow textAlign="center">{eyebrow}</Eyebrow>}
    {headline || subheadline ? (
      <Header>
        <Subheadline>{subheadline}</Subheadline>
        <Headline as={level}>{headline}</Headline>
      </Header>
    ) : null}
    <Container>
      <ImagePanel>
        <ImageOuter>
          <ImageWrapper>
            {image && (
              <ResponsiveImage
                src={image.src}
                alt={image.alt}
                height={image.height}
                width={image.width}
                style={{ width: '100%', height: '100%' }}
                objectFit="cover"
              />
            )}
          </ImageWrapper>
        </ImageOuter>
      </ImagePanel>
      <DetailsWrapper>
        <Details
          title={title}
          icon={icon}
          notice={notice}
          description={description}
          details={details}
          buttonVariant={buttonVariant}
          link={link}
          headerText={headline}
        />
      </DetailsWrapper>
    </Container>
  </StyledRecipeInformationSection>
);

RecipeInformationSection.displayName = 'RecipeInformationSection';

RecipeInformationSection.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  icon: PropTypes.object,
  notice: PropTypes.string,
  description: PropTypes.node.isRequired,
  details: PropTypes.array,
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string.isRequired,
  link: PropTypes.object,
  eyebrow: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
