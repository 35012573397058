'use client';
import styled, { css } from 'styled-components';

export const Card = styled.div`
  border-radius: 32px;
  border: 2px solid
    ${props =>
      props.checked ? props.theme.colors.secondary.egg : 'transparent'};
  display: inline-flex;
  flex-direction: column;
  user-select: none;
  overflow: hidden; /* prevent overflow on rounded corners */
  position: relative; /* allow absolute positioning of child elements */
  width: ${props => (props.orientation === 'column' ? '155px' : '345px')};
  max-height: ${props => (props.orientation === 'column' ? '155px' : '110px')};
  background: ${({ theme, unavailable }) =>
    unavailable
      ? theme.colors.background.disabled
      : theme.colors.background.lightest};
  opacity: ${({ $notSelectable }) => ($notSelectable ? 0.5 : 1)};
  color: ${({ theme }) => theme.colors.text.primary};
  padding: ${props =>
    props.orientation === 'column'
      ? props.theme.spacing(2, 1)
      : props.theme.spacing(0, 0, 15.5)};
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => props.orientation};
  flex: 1;
  gap: 1px;
  padding: ${props =>
    props.orientation === 'column' ? '0' : props.theme.spacing(1, 2)};
`;

export const StyledImage = styled.div`
  display: flex;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
  object-position: left;
  object-fit: cover;
  height: ${props => (props.orientation === 'column' ? '85px' : '100px')};
  width: ${props => (props.orientation === 'column' ? '85px' : '100px')};
  filter: ${props => props.unavailable && 'grayscale(100%) brightness(93%)'};

  ${props =>
    !props.checked &&
    !props.unavailable &&
    props.orientation === 'column' &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
      }
    `}
`;

export const Info = styled.div`
  max-width: 251px;
  padding-left: ${props =>
    props.orientation === 'column' ? '0px' : props.theme.spacing(2)};
`;

export const CardHeader = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(4)};
`;

export const Name = styled.h5`
  ${({ theme }) => theme.typography.heading5}
  text-transform: capitalize;
`;

export const Description = styled.p`
  display: ${props => (props.orientation === 'column' ? 'none' : 'inline')};
  ${({ theme }) => theme.typography.subheading2}
  color: ${props => props.theme.colors.text.secondary};
  margin-top: ${props => props.theme.spacing(1)};
  text-transform: uppercase;
`;

export const MoreDetails = styled.button`
  ${({ theme }) => theme.typography.body}
  left: ${props => props.theme.spacing(4)};
  bottom: ${props => props.theme.spacing(8)};
  align-items: center;
  display: flex;
  border: none;
  padding: 0 3px;
  visibility: ${props =>
    props.unavailable || props.disabled ? 'hidden' : 'visible'};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.hero.huckleberry};
  text-align: left;
  z-index: 1;

  svg {
    margin-left: ${props => props.theme.spacing(1)};
  }
  &:focus {
    border-radius: ${props => props.theme.spacing(2)};
    outline: 2px solid ${({ theme }) => theme.colors.neutral.darkestFur};
  }
`;
