import * as yup from 'yup';
import { FeedingTab } from '@web/components';
import { requiredImageFromCMSSchema, richTextFromCMSSchema } from '../schemas';

export const component = FeedingTab;

export const schema = yup
  .object({
    sections: yup.array(
      yup.object({
        headline: yup.string(),
        headingLevel: yup.string(),
        body: richTextFromCMSSchema,
      }),
    ),
    assets: yup.array(
      yup.object({
        image: requiredImageFromCMSSchema,
        headline: yup.string(),
        body: yup.string(),
      }),
    ),
    cta: yup
      .object({
        buttonVariant: yup.string().optional(),
        linkTarget: yup.string().optional(),
        linkText: yup.string().optional(),
      })
      .optional(),
  })
  .transform(value => ({
    sections: value.sections.map(section => ({
      headline: section.headline,
      headingLevel: section.heading_level,
      body: section.body,
    })),
    assets: value.assets,
    cta:
      value.link_target && value.link_text
        ? {
            linkTarget: value.link_target?.url,
            linkText: value.link_text,
            buttonVariant: value.button_variant,
          }
        : undefined,
  }));
