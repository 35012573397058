'use client';
import styled, { css } from 'styled-components';
import { generateContentIconStyle } from '@ui/icons';

export const StyledCarousel = styled.div`
  padding: ${props => props.paddingTop}
    ${props => props.theme.spacing(props.verticalPadding, props.paddingBottom)};
  position: relative;
  max-width: 100%;
  max-height: 100%;
  @media ${props => props.theme.laptop} {
    padding: ${props => props.paddingTop}
      ${props =>
        props.theme.spacing(props.verticalPadding, props.paddingBottom)};
  }
`;

export const SlideViewport = styled.div`
  overflow: hidden;
  height: 100%;
`;

export const SlideContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const SlideContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DotContainer = styled.div`
  display: flex;
  width: max-content;
  justify-content: space-around;
  margin: auto;
`;

export const ArrowButton = styled.button`
  display: ${props => props.hideArrowMobile && 'none'};
  background: ${props =>
    props.$arrowBgColor && props.theme.colors.get(props.$arrowBgColor)};
  border: ${props =>
    props.$showArrowBorder
      ? `2px solid ${props.theme.colors.get(props.$arrowBgColor)}`
      : 'none'};
  border-radius: 60%;
  padding: 0px;
  width: 50px;
  height: 50px;
  position: ${props => (props.overlappingArrows ? 'absolute' : 'unset')};
  top: ${props => props.buttonPosition}%;
  transform: translateY(
    ${props => (props.overlappingArrows ? props.buttonPosition - 100 : '0')}%
  );
  z-index: 10;
  &:focus {
    border: ${props =>
      props.$showArrowBorder
        ? `2px solid ${props => props.theme.colors.text.primary}`
        : 'none'};
  }
  &:disabled {
    display: none;
  }
  &:hover:not([disabled]) {
    background: ${props =>
      props.$arrowBgColor && props.theme.colors.get(props.$arrowBgColor)};
    border: ${props =>
      props.$showArrowBorder
        ? `2px solid ${props.theme.colors.get(props.$arrowBgColor)}`
        : 'none'};
  }
  @media ${props => props.theme.tablet} {
    display: block;
  }
`;

export const PrevButton = styled(ArrowButton)`
  left: ${props => props.arrowButtonPadding};
  right: unset;
  &::before {
    top: 1px;
    position: relative;
    ${generateContentIconStyle('arrow-left')};
    width: 21px;
    height: 21px;
    background: ${props => props.theme.colors.background.lightest};
  }
`;

export const NextButton = styled(ArrowButton)`
  left: unset;
  right: ${props => props.arrowButtonPadding};
  &::before {
    top: 1px;
    position: relative;
    ${generateContentIconStyle('arrow-forward')};
    width: 21px;
    height: 21px;
    background: ${props => props.theme.colors.background.lightest};
  }
`;
export const BottomContainer = styled.div`
  position: static;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${props => props.theme.spacing(props.dotPadding)};
  width: max-content;
  margin: auto;

  ${props =>
    props.overlappingDots &&
    css`
      position: relative;
      bottom: 36px;
      @media ${props => props.theme.laptop} {
        bottom: 50px;
      }
    `}
`;
export const DotButton = styled.button`
  background-color: ${({ active, $dotColor, theme }) =>
    active ? theme.colors.get($dotColor) : 'transparent'};
  border: 1px solid
    ${props => props.$dotColor && props.theme.colors.get(props.$dotColor)};
  border-radius: 60%;
  cursor: pointer;
  display: flex;
  height: 16px;
  width: 16px;
  padding: 0;
  margin: ${props => props.theme.spacing(1)};
  justify-content: center;
  &:focus,
  &:not([disabled]):hover {
    border-width: 2px;
  }
`;

export const Slide = styled.div`
  flex: 0 0 auto;
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: ${props => (props.fullWidth ? 0 : `0 3%`)};

  @media ${props => props.theme.tablet} {
    flex: none;
    padding: 0;
    width: ${props =>
      props.itemsToShow > 1 ? `calc(100%/(${props.itemsToShow}/2))` : '100%'};
  }

  @media ${props => props.theme.tabletL} {
    width: ${props =>
      props.itemsToShow > 1
        ? `calc(100%/(${props.itemsToShow}/1.33))`
        : '100%'};
  }

  @media (min-width: 1091px) {
    width: ${props =>
      props.itemsToShow ? `calc(100%/${props.itemsToShow})` : 'auto'};
  }
`;

export const ImageDot = styled.button`
  appearance: none;
  background-image: url('${props => props.src}');
  border: 0;
  border-radius: 28px;
  width: 56px;
  height: 56px;
  margin: ${props => props.theme.spacing(0, 2)};
  opacity: ${props => (props.active ? 1 : 0.2)};
  overflow: hidden;
  padding: 0;
  position: relative;
  @media ${props => props.theme.laptop} {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: ${props => props.theme.spacing(0, 4)};
  }
`;
