import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { Heading } from '@web/atoms';
import {
  CardContainer,
  ImageWrapper,
  ContentWrapper,
  NumberCircle,
  TitleWrapper,
  BodyWrapper,
} from './styles';

export const SimpleCalloutCard = ({
  image,
  heading,
  body,
  headingLevel = 'h3',
  icon,
  align,
}) => {
  return (
    <CardContainer $align={align}>
      <ImageWrapper>
        <ResponsiveImage {...image} />
      </ImageWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <NumberCircle>
            <ResponsiveImage {...icon} />
          </NumberCircle>
          <Heading
            headingLevel={headingLevel}
            typography="subheading2"
            headingText={heading}
            position="center"
            verticalPadding={0}
            horizontalPadding={2}
          />
        </TitleWrapper>
        <BodyWrapper>{body}</BodyWrapper>
      </ContentWrapper>
    </CardContainer>
  );
};

SimpleCalloutCard.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  headingLevel: PropTypes.string,
  icon: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  align: PropTypes.string,
};
