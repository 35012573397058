import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.get(props.$backgroundColor)};
`;

export const TabList = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  border-bottom: ${props => `1px solid ${props.theme.colors.border.fur}`};
  @media ${props => props.theme.tablet} {
    gap: 32px;
  }
`;

export const TabButton = styled.button`
  ${props => props.theme.typography.subheading1}
  background-color: transparent;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px 0 12px 0;
  text-align: center;
  position: relative;
  border: none;
  color: ${props =>
    props.isActive
      ? props.theme.colors.text.primary
      : props.theme.colors.get(props.$fontColor)};
  border-bottom: ${props =>
    props.isActive
      ? `3px solid ${props.theme.colors.hero.blueberry}`
      : '3px solid transparent'};

  &:last-child {
    margin-right: 0;
  }
`;

export const TabContent = styled.div`
  padding: 20px 16px;
  margin-top: 10px;

  @media ${props => props.theme.tablet} {
    padding: 20px;
  }
`;
