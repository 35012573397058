import React from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/image';
import { Tooltip, PillText } from '@web/atoms';
import {
  Card,
  Info,
  StyledImage,
  Name,
  Description,
  CardContent,
  CardHeader,
  MoreDetails,
} from './styles.js';

export const RecommendedProductCard = ({
  label,
  title,
  badge,
  description,
  checked = false,
  img,
  onChange,
  onDetailsClick,
  showMoreDetails,
  unavailable,
  unavailableText,
  notSelectable,
  notSelectableText = 'To choose this item, please remove one of your previous selections.',
  level,
  orientation = 'row',
}) => {
  const getProductCard = props => (
    <Card
      orientation={orientation}
      {...props}
      checked={checked}
      unavailable={unavailable}
      $notSelectable={notSelectable}
      aria-label={`${label} ${title}`}
      onClick={!notSelectable && onChange}
    >
      <CardContent orientation={orientation}>
        {orientation === 'column' && badge && (
          <PillText text={badge} variant="kale" size="small" />
        )}
        <StyledImage
          checked={checked}
          unavailable={unavailable}
          orientation={orientation}
        >
          <NextImage src={img.src} alt={img.alt} width={82} height={82} />
        </StyledImage>
        <Info checked={checked} orientation="column">
          <CardHeader>
            <Name as={level}>{title}</Name>
          </CardHeader>
          <Description orientation={orientation}>
            {unavailable ? unavailableText : description}
          </Description>
          {showMoreDetails && (
            <MoreDetails
              type="button"
              unavailable={unavailable}
              onClick={e => {
                e.stopPropagation();
                onDetailsClick();
              }}
              disabled={!showMoreDetails}
            >
              View Ingredients
            </MoreDetails>
          )}
        </Info>
      </CardContent>
    </Card>
  );

  return (notSelectable && !unavailable) || unavailable ? (
    <Tooltip trigger={getProductCard} placement="top" offsetY={-200}>
      {notSelectableText}
    </Tooltip>
  ) : (
    getProductCard()
  );
};

RecommendedProductCard.displayName = 'RecommendedProductCard';
RecommendedProductCard.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  benefit: PropTypes.string,
  unavailable: PropTypes.bool,
  unavailableText: PropTypes.string,
  unavailableLabel: PropTypes.string,
  notSelectable: PropTypes.bool,
  notSelectableText: PropTypes.string,
  value: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  checked: PropTypes.bool,
  img: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  callout: PropTypes.string,
  showPriceText: PropTypes.bool,
  period: PropTypes.string,
  badge: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
