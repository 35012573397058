import * as yup from 'yup';
import { IngredientsTab } from '@web/components';
import { requiredImageFromCMSSchema } from '../schemas';
import { schema as nutritionSchema } from './recipe_nutrient';

export const component = IngredientsTab;

const transformAssetValues = assets =>
  assets.map(asset => ({
    name: asset.name,
    description: asset.description,
    image: asset.image,
  }));

export const schema = yup
  .object({
    headline: yup.string(),
    subheadline: yup.string(),
    assets: yup.array(
      yup.object({
        name: yup.string(),
        description: yup.string(),
        image: requiredImageFromCMSSchema,
      }),
    ),
    caloriesInfo: yup.string(),
    fullIngredienfo: yup.string(),
    bgColor: yup.string(),
    nutritionalInfo: yup.array(nutritionSchema),
    headingLevel: yup.string(),
  })
  .transform(value => ({
    headline: value.headline,
    subheadline: value.subheadline,
    assets: transformAssetValues(value.assets),
    caloriesInfo: value.caloriesInfo,
    fullIngredientsInfo: value.fullIngredientsInfo,
    bgColor: value.bgColor,
    nutritionalInfo: value.nutritionalInfo,
    headingLevel: value.headingLevel,
  }));
