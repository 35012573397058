'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@web/atoms';
import { ResponsiveImage } from '@web/molecules';
import {
  Container,
  Subheadline,
  StyledIngredientsTab,
  Ingredient,
  IngredientName,
  IngredientDescription,
  AssetsContainer,
  ImageWrapper,
  SectionTitle,
  CalorieText,
  FullIngredientsText,
  NutrientTable,
  NutrientRow,
  NutrientCell,
} from './styles';

export const IngredientsTab = ({
  headline,
  subheadline,
  assets,
  bgColor = 'background.lightest',
  caloriesInfo,
  fullIngredientsInfo,
  nutritionalInfo,
  headingLevel = 'h4',
}) => {
  const parseNutrients = nutrient => {
    const nutrients = nutrient.split(' ');
    const percentValue = nutrients.find(value => value.includes('%'));
    const minMaxValue = nutrients.find(
      value => value === 'min' || value === 'max',
    );
    return { percentValue, minMaxValue };
  };

  return (
    <StyledIngredientsTab $bgColor={bgColor}>
      <Container>
        <Heading
          headingLevel={headingLevel}
          typography="heading4"
          headingText={headline}
          position="start"
          backgroundColor={bgColor}
          horizontalPadding={2}
          verticalPadding={1}
        />
        <Subheadline>{subheadline}</Subheadline>
        <AssetsContainer>
          {assets?.map((ingredient, i) => (
            <Ingredient key={i}>
              <ImageWrapper>
                <ResponsiveImage
                  {...ingredient.image}
                  style={{ height: '100%' }}
                  objectFit="cover"
                />
              </ImageWrapper>
              <IngredientName>{ingredient.name}</IngredientName>
              <IngredientDescription>
                {ingredient.description}
              </IngredientDescription>
            </Ingredient>
          ))}
        </AssetsContainer>
        <SectionTitle>Calorie Content</SectionTitle>
        <CalorieText>{caloriesInfo}</CalorieText>
        <SectionTitle>About the nutrition</SectionTitle>
        <NutrientTable>
          {nutritionalInfo?.map((nutrient, i) => (
            <NutrientRow key={i}>
              <NutrientCell>{nutrient.label}</NutrientCell>
              <NutrientCell>
                {parseNutrients(nutrient.value).percentValue}
              </NutrientCell>
              <NutrientCell>
                {parseNutrients(nutrient.value).minMaxValue}
              </NutrientCell>
            </NutrientRow>
          ))}
        </NutrientTable>
        <SectionTitle>Full Ingredients List</SectionTitle>
        <FullIngredientsText>{fullIngredientsInfo}</FullIngredientsText>
      </Container>
    </StyledIngredientsTab>
  );
};

IngredientsTab.displayName = 'IngredientsTab';

IngredientsTab.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        src: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
        alt: PropTypes.string.isRequired,
      }),
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  caloriesInfo: PropTypes.string,
  fullIngredientsInfo: PropTypes.string,
  nutritionalInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
