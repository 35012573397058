'use client';
import styled from 'styled-components';

export const StyledIngredientsTab = styled.div`
  background-color: ${props => props.theme.colors.get(props.$bgColor)};

  @media ${props => props.theme.tabletL} {
    margin: 0 16% 0 13%;
  }
`;

export const ImageWrapper = styled.div`
  aspect-ratio: 1 / 1;
  max-width: 250px;
  border-radius: 32px;
  overflow: hidden;
`;

export const AssetsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: ${props => props.theme.spacing(4)};

  @media ${props => props.theme.tablet} {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  padding-bottom: ${props => props.theme.spacing(2)};
`;
export const Ingredient = styled.div`
  width: 100%;

  @media ${props => props.theme.tablet} {
    width: 25%;
    max-width: 250px;
  }
`;

export const Container = styled.div``;

export const Subheadline = styled.h2`
  ${props => props.theme.typography.button};
  margin-top: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(6)};
  padding-left: ${props => props.theme.spacing(2)};
`;

export const SectionTitle = styled.h6`
  ${props => props.theme.typography.heading6};
  padding: ${props => props.theme.spacing(6, 0, 3)};
`;

export const IngredientName = styled.p`
  ${props => props.theme.typography.bodyBold};
  color: ${props => props.theme.colors.text.secondary};
  margin-top: ${props => props.theme.spacing(4)};
`;

export const IngredientDescription = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.secondary};
  text-wrap: pretty;
`;

export const CalorieText = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.primary};
`;

export const FullIngredientsText = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.secondary};
`;

export const NutrientTable = styled.table`
  min-width: 340px;
  border-spacing: 0px;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.primary};
  padding-bottom: ${props => props.theme.spacing(3)};
`;

export const NutrientRow = styled.tr`
  &:nth-child(odd) {
    background: ${props => props.theme.colors.background.base};
  }
`;
export const NutrientCell = styled.td`
  padding: ${props => props.theme.spacing(1)};
  &:first-child {
    padding-left: ${props => props.theme.spacing(3)};
  }
  &:last-child {
    padding-right: ${props => props.theme.spacing(3)};
    text-align: right;
  }
`;
