import React from 'react';
import PropTypes from 'prop-types';
import { Carousel, CtaButton } from '@web/molecules';
import { SectionIntro, BackgroundColorContainer, Eyebrow } from '@web/atoms';
import { HoverImageCard } from '../HoverImageCard';
import {
  StyledImageCardsList,
  ImageCardsListContainer,
  MobileImageCardsListContainer,
  CardWrapper,
  ButtonContainer,
} from './styles.js';

export const HoverImageCards = ({
  headline,
  subheadline,
  items,
  isCarousel = true,
  link,
  buttonVariant = 'primary',
  backgroundColor = 'background.base',
  fontColor = 'text.secondary',
  eyebrow,
  level,
  headerText,
}) => {
  return (
    <BackgroundColorContainer $bgColor={backgroundColor}>
      <StyledImageCardsList $fontColor={fontColor}>
        {eyebrow && <Eyebrow fontColor={fontColor}>{eyebrow}</Eyebrow>}
        {(headline || subheadline) && (
          <SectionIntro
            headline={headline}
            subheadline={subheadline}
            headingLevel={level}
          />
        )}
        <ImageCardsListContainer count={items.length} isCarousel={isCarousel}>
          {items.map((item, i) => (
            <CardWrapper key={i}>
              <HoverImageCard {...item} />
            </CardWrapper>
          ))}
        </ImageCardsListContainer>
        <MobileImageCardsListContainer isCarousel={isCarousel}>
          <Carousel buttonPosition="40" dotPadding={6} showDots={false}>
            {items.map((item, i) => (
              <CardWrapper key={i}>
                <HoverImageCard {...item} />
              </CardWrapper>
            ))}
          </Carousel>
        </MobileImageCardsListContainer>
        {link && (
          <ButtonContainer $bgColor={backgroundColor}>
            <CtaButton
              href={link.target}
              variant={buttonVariant}
              headerText={headerText}
            >
              {link.text}
            </CtaButton>
          </ButtonContainer>
        )}
      </StyledImageCardsList>
    </BackgroundColorContainer>
  );
};

HoverImageCards.displayName = 'ImageCardsList';

HoverImageCards.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCarousel: PropTypes.bool,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  buttonVariant: PropTypes.string,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  eyebrow: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
