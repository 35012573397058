import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import { Heading } from '@web/atoms';
import {
  ShowcaseContainer,
  ContentContainer,
  ImagesContainer,
  BodyContainer,
  BulletPoint,
  UnorderedList,
  ButtonWrapper,
  ButtonContainer,
  Subheadline,
} from './styles';

export const DetailsTab = ({
  headline,
  level,
  images,
  bullets,
  linkTarget,
  subheadline,
  linkText,
  buttonVariant,
  backgroundColor,
  fontColor = 'text.primary',
}) => {
  return (
    <ShowcaseContainer
      $fontColor={'text.secondary'}
      $backgroundColor={backgroundColor}
    >
      <Heading
        position="left"
        typography="heading4"
        headingText={headline}
        headingLevel={level}
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        horizontalPadding={2}
        verticalPadding={1}
      />
      <Subheadline>{subheadline}</Subheadline>
      <ContentContainer>
        <ImagesContainer>
          {images.map((img, idx) => (
            <ResponsiveImage
              key={idx}
              src={img.src}
              width={163}
              height={163}
              alt={img.alt}
            />
          ))}
        </ImagesContainer>
        <BodyContainer>
          <UnorderedList>
            {bullets.map(({ bullet }, idx) => (
              <BulletPoint key={idx}>{bullet}</BulletPoint>
            ))}
          </UnorderedList>
          <ButtonContainer>
            <ButtonWrapper>
              <LinkButton
                variant={buttonVariant}
                href={linkTarget}
                horizontalPadding={24}
              >
                {linkText}
              </LinkButton>
            </ButtonWrapper>
          </ButtonContainer>
        </BodyContainer>
      </ContentContainer>
    </ShowcaseContainer>
  );
};

DetailsTab.propTypes = {
  headline: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  ).isRequired,
  bullets: PropTypes.arrayOf(
    PropTypes.shape({
      bullet: PropTypes.string.isRequired,
    }),
  ).isRequired,
  subheadline: PropTypes.string.isRequired,
  linkTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  linkText: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
};
