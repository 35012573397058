'use client';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CarouselDots, ResponsiveImage } from '@web/molecules';
import { Heading } from '@web/atoms';
import { sideScroll } from '../../hooks/index.js';
import {
  StyledComparisonChart,
  Container,
  Content,
  ChartItems,
  ChartColumn,
  ComparisonColumnItem,
  ColumnHeader,
  ColumnsContainer,
  CarouselDotsContainer,
  Paragraph,
} from './styles.js';
import DarkIcon from './dark_icon.svg';
import LightIcon from './light_icon.svg';
import OllieIcon from './ollie_font.svg';
import { ComparisonChartItem } from './ComparisonChartItem';

export const ComparisonChart = ({
  heading,
  description,
  rows,
  columns,
  level,
}) => {
  const contentWrapper = useRef(null);
  const [activePage, setActivePage] = useState(0);

  const onScroll = () => {
    let scrollFlag = contentWrapper?.current.scrollLeft;
    if (scrollFlag === 0) {
      setActivePage(0);
    }
    if (scrollFlag > 90 && scrollFlag < 260) {
      setActivePage(1);
    }
    if (scrollFlag > 200 && columns.length === 4) {
      setActivePage(2);
    }
    if (scrollFlag > 300 && columns.length > 4) {
      setActivePage(2);
    }
  };

  return (
    <StyledComparisonChart background={'background.base'}>
      <Heading
        headingLevel={level}
        typography={'heading2'}
        headingText={heading}
        position="center"
        verticalPadding={2}
      />
      <Paragraph>{description}</Paragraph>
      <Container ref={contentWrapper} onScroll={onScroll}>
        <Content>
          {rows && (
            <ChartItems>
              <ComparisonChartItem key={'empty'} header={''} subheader={''} />
              {rows.map((feature, i) => (
                <ComparisonChartItem
                  key={i}
                  header={feature.header}
                  subheader={feature.subheader}
                />
              ))}
            </ChartItems>
          )}
          {columns && (
            <ColumnsContainer items={columns.length}>
              {columns.map((column, i) => (
                <ChartColumn
                  highlighted={column.highlighted}
                  key={i}
                  items={columns.length}
                >
                  <ComparisonColumnItem>
                    {column.highlighted === true ? (
                      <ResponsiveImage
                        src={OllieIcon.src}
                        alt="Ollie logo"
                        width={OllieIcon.width}
                        height={OllieIcon.height}
                      />
                    ) : (
                      <ColumnHeader as={column.level || 'h2'}>
                        {column.label}
                      </ColumnHeader>
                    )}
                  </ComparisonColumnItem>

                  {rows.map((row, j) => (
                    <ComparisonColumnItem
                      highlighted={column.highlighted}
                      key={j}
                    >
                      {row.comparisonRowMarkers[i]?.on === true ? (
                        <ResponsiveImage
                          key={column.label}
                          src={
                            column.highlighted ? LightIcon.src : DarkIcon.src
                          }
                          alt="Ollie logo"
                          width={
                            column.highlighted
                              ? LightIcon.width
                              : DarkIcon.width
                          }
                          height={
                            column.highlighted
                              ? LightIcon.height
                              : DarkIcon.height
                          }
                        />
                      ) : null}
                    </ComparisonColumnItem>
                  ))}
                </ChartColumn>
              ))}
            </ColumnsContainer>
          )}
        </Content>
      </Container>
      <CarouselDotsContainer>
        <CarouselDots
          activePage={activePage}
          pages={3}
          variant="darkestFur"
          onClick={page =>
            sideScroll(columns, page, contentWrapper.current, 25, 150, 160)
          }
        ></CarouselDots>
      </CarouselDotsContainer>
    </StyledComparisonChart>
  );
};

ComparisonChart.displayName = 'ComparisonChart';

ComparisonChart.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      subheader: PropTypes.string,
      comparisonRowMarkers: PropTypes.arrayOf(
        PropTypes.shape({
          on: PropTypes.bool,
          off: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      highlighted: PropTypes.bool,
    }),
  ).isRequired,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
