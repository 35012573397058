import styled from 'styled-components';

export const StyledFeedingTab = styled.div`
  display: flex;
  justify-content: center;
  background: white;
  padding: ${props => props.theme.spacing(6, 4)};
`;

export const TabContentContainer = styled.div`
  max-width: 1080px;
`;

export const SectionHeading = styled.div`
  ${({ theme }) => theme.typography.heading4}
  padding: ${props => props.theme.spacing(0, 0, 2)};
`;

export const SectionBody = styled.div`
  ${({ theme }) => theme.typography.body}
  padding: ${props => props.theme.spacing(0, 0, 8)};
`;

export const TabContentSection = styled.div``;

export const AssetWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spacing(0, 0, 4)};
  margin-top: ${props => props.theme.spacing(-8)};

  @media ${({ theme }) => theme.mobileS} {
    flex-direction: column;
  }

  @media ${({ theme }) => theme.laptop} {
    flex-direction: row;
  }
`;

export const AssetContainer = styled.div`
  @media ${({ theme }) => theme.mobileS} {
    display: inline-flex;
    margin-left: ${props => props.theme.spacing(-4)};
  }

  @media ${({ theme }) => theme.laptop} {
    justify-content: space-around;
    display: flex;
    align-content: start;
    flex-wrap: wrap;
  }
`;

export const AssetImage = styled.div``;

export const AssetText = styled.div`
  padding: ${props => props.theme.spacing(2)};
  margin: auto;
  width: 100%;

  @media ${({ theme }) => theme.laptop} {
    text-align: left;
    width: 250px;
    margin-top: -8px;
  }
`;

export const AssetHeadline = styled.h4`
  ${({ theme }) => theme.typography.body}
  font-weight: bold;
`;

export const AssetBody = styled.p`
  ${({ theme }) => theme.typography.body}
`;

export const ButtonContainer = styled.div`
  padding: ${props => props.theme.spacing(2)};

  @media ${({ theme }) => theme.mobileS} {
    text-align: center;
  }

  @media ${({ theme }) => theme.laptop} {
    text-align: left;
    width: fit-content;
  }
`;
