'use client';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { loadTrustPilot } from '../TrustPilot/trustpilot';
import { StyledTrustPilotList, ReviewsContainer } from './styles';

export const TrustPilotList = ({ templateId, businessUnitId, locale }) => {
  const widgetRef = useRef();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadTrustPilot(() => {
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (loaded && widgetRef.current) {
      window.Trustpilot?.loadFromElement(widgetRef.current, true);
    }
  }, [loaded]);

  return (
    <StyledTrustPilotList>
      <ReviewsContainer
        ref={widgetRef}
        className="trustpilot-widget"
        data-template-id={templateId}
        data-businessunit-id={businessUnitId}
        data-locale={locale}
        data-style-height="500PX"
        data-style-width="100%"
        data-theme="light"
      >
        <a
          href="https://www.trustpilot.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </ReviewsContainer>
    </StyledTrustPilotList>
  );
};

TrustPilotList.propTypes = {
  templateId: PropTypes.string.isRequired,
  businessUnitId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

TrustPilotList.displayName = 'TrustPilotList';
