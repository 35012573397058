'use client';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: ${props => props.theme.spacing(9)};
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline(props.$bgColor)}
`;

export const InfoContainer = styled.div`
  margin-top: ${props => props.theme.spacing(5)};
`;

export const ImageContainer = styled.div`
  background-color: ${props =>
    props.$bgColor && props.theme.colors.get(props.$bgColor)};

  @media ${props => props.theme.mobileS} {
    grid-row: 1;
    grid-column: 1;
    margin-bottom: ${props => props.theme.spacing(-1)};
  }

  @media ${props => props.theme.tablet} {
    grid-row: ${props => (props.imageFirst ? '1' : 'auto')};
    grid-column: ${props => (props.imageFirst ? '1' : 'auto')};

    img {
      width: auto;
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
    }
  }
`;

export const Content = styled.div`
  padding: ${props => props.theme.spacing(13, 7)};
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  color: ${props =>
    props.$fontColor && props.theme.colors.get(props.$fontColor)};
  background-color: ${props =>
    props.$bgColor && props.theme.colors.get(props.$bgColor)};

  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(13, 20)};
  }

  @media ${props => props.theme.laptopL} {
    padding: ${props => props.theme.spacing(13, 37)};
  }
`;

export const StyledSplitScreenPromo = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media ${props => props.theme.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  &:last-child {
    margin-bottom: 70px;
  }
  overflow: hidden;
`;
