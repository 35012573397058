import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, CtaButton } from '@web/molecules';
import {
  StyledSimpleCallout,
  Inner,
  ImageWrapper,
  Content,
  Body,
  ButtonContainer,
  ButtonWrapper,
} from './styles.js';

export const SimpleCallout = ({ image, body, level = 'h2', link }) => {
  return (
    <StyledSimpleCallout>
      <Inner>
        <ImageWrapper>
          <ResponsiveImage {...image} />
        </ImageWrapper>
        <Content>
          <Body as={level}>{body}</Body>
        </Content>
        <ButtonContainer>
          <ButtonWrapper>
            <CtaButton href={link.target} variant="kale" headerText={body}>
              {link.text}
            </CtaButton>
          </ButtonWrapper>
        </ButtonContainer>
      </Inner>
    </StyledSimpleCallout>
  );
};
SimpleCallout.displayName = 'SimpleCallout';

SimpleCallout.propTypes = {
  image: PropTypes.object.isRequired,
  body: PropTypes.string.isRequired,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }).isRequired,
};
