import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import { Heading } from '@web/atoms';
import {
  StyledLearnMoreContainer,
  InfoContainer,
  BodyText,
  ButtonContainer,
  ButtonWrapper,
} from './styles.js';

export const LearnMore = ({
  img,
  heading,
  body,
  buttonUrl,
  buttonLabel,
  backgroundColor,
  buttonVariant,
  fontColor,
  level,
}) => {
  return (
    <StyledLearnMoreContainer
      $fontColor={fontColor}
      $backgroundColor={backgroundColor}
    >
      <ResponsiveImage {...img} />
      <InfoContainer>
        <Heading
          headingLevel={level}
          typography={'heading3'}
          headingText={heading}
          position="center"
          backgroundColor={backgroundColor}
          verticalPadding={1}
        />
        <BodyText>{body}</BodyText>
      </InfoContainer>
      <ButtonContainer>
        <ButtonWrapper $backgroundColor={backgroundColor}>
          <LinkButton
            verticalPadding={4}
            horizontalPadding={22}
            variant={buttonVariant}
            href={buttonUrl}
          >
            {buttonLabel}
          </LinkButton>
        </ButtonWrapper>
      </ButtonContainer>
    </StyledLearnMoreContainer>
  );
};

LearnMore.propTypes = {
  img: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
