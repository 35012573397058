import * as yup from 'yup';
import { SwipeHero } from '@web/components';
import { linkSchema, inlineRichTextFromCMSSchema } from '../schemas';
import { schema as muxVideoSchema } from './mux_video';
import { schema as imageSchema } from './image';

export const component = SwipeHero;

export const schema = yup
  .object({
    headline: yup.string(),
    subheadline: yup.string(),
    bodyImage: imageSchema.nullable(),
    body: inlineRichTextFromCMSSchema,
    assets: yup
      .array(
        yup.lazy(item => {
          switch (item.component) {
            case 'mux_video':
              return muxVideoSchema.required();
            default:
              return imageSchema;
          }
        }),
      )
      .required(),
    cta: linkSchema,
    ctaColor: yup.string(),
  })
  .transform(value => ({
    headline: value.headline,
    subheadline: value.subheadline,
    body: value.body,
    bodyImage: value.bodyImage?.[0],
    assets: value.assets,
    cta: value.cta?.[0],
    ctaColor: value.ctaColor,
  }));
