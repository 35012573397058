import * as yup from 'yup';
import { Tabs } from '@web/components/Tabs';
import { CMSBlok } from '../translation';

export const component = Tabs;

const tabSchema = yup.object({
  name: yup.string().required(),
  content: yup.object(),
  fontColor: yup.string(),
});

export const schema = yup
  .object({
    tabs: yup.array(tabSchema).required(),
    backgroundColor: yup.string().nullable(),
  })
  .transform(value => ({
    tabs: value.tabs.map(tab => ({
      name: tab.tab_name,
      content: <CMSBlok content={tab.tab_content[0]} />,
      fontColor: tab.font_color || '#82746C', // Default to black if no color is provided
    })),
    backgroundColor: value.background_color || '#FFFFFF',
  }));
