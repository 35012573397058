import styled from 'styled-components';

export const ShowcaseContainer = styled.div`
  background-color: ${props => props.theme.colors.get(props.$backgroundColor)};
  padding: ${props => props.theme.spacing(1)};
  padding-top: 0;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  h6 {
    padding: 0;
  }
  margin: 0 auto;
  color: ${props => props.theme.colors.get(props.$fontColor)};
  @media ${({ theme }) => theme.laptop} {
    border-radius: 32px;
    padding: 0 ${props => props.theme.spacing(20)};
    padding: ${props => props.theme.spacing(2)} 40px;
    max-width: 1100px;
  }
`;

export const Headline = styled.h2`
  font-size: ${props => props.headlineSize || '24px'};
  color: ${props => props.theme.colors.get(props.$fontColor)};
  text-align: left;
  margin-bottom: 20px;

  @media ${({ theme }) => theme.laptop} {
    font-size: ${props => props.headlineSize || '32px'};
    margin-bottom: 40px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack on mobile */
  gap: 15px;
  width: 100%;

  @media ${({ theme }) => theme.laptop} {
    flex-direction: row; /* Row layout on desktop */
    align-items: flex-start;
    gap: 30px;
  }
`;

export const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two images per row */
  gap: 16px;
  width: 100%;
  max-width: 340px;
`;

export const BodyContainer = styled.div`
  display: flex;
  ${props => props.theme.typography.button}
  flex-direction: column;
  width: 100%;

  text-align: left;

  @media ${({ theme }) => theme.laptop} {
    width: 65%; /* 65% width on desktop */
    ul {
      margin-top: 0;
    }
  }
`;

export const Subheadline = styled.div`
  ${props => props.theme.typography.button}
  padding: ${props => props.theme.spacing(3)} 0 ${props =>
    props.theme.spacing(6)} 0;
`;

export const BulletPoint = styled.li`
  margin-bottom: 10px;
  ${props => props.theme.typography.button}

  text-align: left;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.laptop} {
    justify-content: flex-start;
  }
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline()}
`;

export const UnorderedList = styled.ul`
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 20px;

  @media ${({ theme }) => theme.laptop} {
    padding-inline-start: 20px;
  }
`;
