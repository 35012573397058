export const heroButtonClicked = (text, target) => ({
  type: 'Hero Button Clicked',
  data: {
    text,
    target,
  },
});

export const appStoreCTAButtonClicked = () => ({
  type: `Button Clicked`,
  data: {
    component_heading: 'user clicks on app cta',
    cta_text: 'clicks app cta',
  },
});
