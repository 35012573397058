import React from 'react';
import PropTypes from 'prop-types';
import { Heading, PillText } from '@web/atoms';
import { ResponsiveImage } from '@web/molecules';
import {
  BeforeAfterTestimonialContainer,
  ImagesContainer,
  CustomPillText,
  BadgeContainer,
  TextContainer,
  BodyTextContainer,
} from './styles';

export const BeforeAfterTestimonial = ({
  badgeColor,
  badge,
  photo1,
  photo2,
  callout,
  name,
  description,
  body,
}) => {
  return (
    <BeforeAfterTestimonialContainer>
      <BadgeContainer>
        <PillText variant={badgeColor} text={badge} />
      </BadgeContainer>
      <ImagesContainer>
        <ResponsiveImage
          src={photo1.src}
          width={128}
          height={128}
          alt={photo1.alt}
        />
        <ResponsiveImage
          src={photo2.src}
          width={128}
          height={128}
          alt={photo2.alt}
        />
      </ImagesContainer>
      <CustomPillText>{callout}</CustomPillText>
      <TextContainer>
        <Heading
          headingLevel="h3"
          typography={'heading3'}
          headingText={name}
          position="center"
          horizontalPadding={2}
          verticalPadding={1}
          backgroundColor={'neutral'}
        />
        <Heading
          headingLevel="h5"
          typography={'heading5'}
          headingText={description}
          position="center"
          horizontalPadding={2}
          verticalPadding={1}
          backgroundColor={'neutral'}
        />

        <BodyTextContainer>{body}</BodyTextContainer>
      </TextContainer>
    </BeforeAfterTestimonialContainer>
  );
};

BeforeAfterTestimonial.displayName = 'BeforeAfterTestimonial';

BeforeAfterTestimonial.propTypes = {
  photo1: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  photo2: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  description: PropTypes.string,
  callout: PropTypes.string,
  body: PropTypes.node,
  name: PropTypes.string.isRequired,
  badge: PropTypes.string,
  badgeColor: PropTypes.string,
};
