import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Dropdown, Heading, Grid } from '@web/atoms';
import { useReporter } from '../../reporter';
import { FeedingGuidelinesRecipeCard } from '../FeedingGuidelinesRecipeCard';
import {
  Container,
  HeadingContainer,
  DropdownContainer,
  HeadingContainerDropdown,
  HeadingContainerText,
} from './styles';
import { dropdownClicked } from './events';

export const FeedingGuidelines = ({
  dropdown,
  heading,
  feedingGuidelinesRecipes,
  level,
}) => {
  const router = useRouter();
  const reporter = useReporter();

  let { recipe, version } = router.query;

  recipe = recipe || feedingGuidelinesRecipes[0].heading;
  version = Number(version || 0);
  if (Number.isNaN(version)) {
    version = 0;
  }

  const onChange = dropdownOption => {
    router.push({
      query: { ...router.query, recipe: dropdownOption, version: '' },
    });
  };

  const onVersionChange = version => {
    router.push({
      query: { ...router.query, version },
    });
  };

  const selectedRecipe = feedingGuidelinesRecipes.find(
    elm => elm.heading.toUpperCase() === recipe.toUpperCase(),
  );

  return (
    <Container>
      <HeadingContainer>
        <Grid gap={{ column: 3 }} columns={{ mobile: 1, laptop: 2 }}>
          <HeadingContainerText>
            <Heading
              headingLevel={level}
              headingText={
                heading.charAt(0).toUpperCase() + heading.slice(1).toLowerCase()
              }
              position="center"
              typography="heading1"
              horizontalPadding={2}
              verticalPadding={1}
            />
          </HeadingContainerText>
          <HeadingContainerDropdown>
            {dropdown && (
              <DropdownContainer>
                <Dropdown
                  name={dropdown.name}
                  label={dropdown.label}
                  options={dropdown.options}
                  onChange={dropdownOption => onChange(dropdownOption)}
                  selected={recipe}
                  noSelectionLabel={dropdown.noSelectionLabel}
                  onClick={() => reporter.tag(dropdownClicked())}
                />
              </DropdownContainer>
            )}
          </HeadingContainerDropdown>
        </Grid>
      </HeadingContainer>
      <FeedingGuidelinesRecipeCard
        selectedRecipe={selectedRecipe}
        version={version}
        onVersionChange={onVersionChange}
      />
    </Container>
  );
};
FeedingGuidelines.displayName = 'FeedingGuidelines';

FeedingGuidelines.propTypes = {
  dropdown: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    noSelectionLabel: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
  }),
  heading: PropTypes.string.isRequired,
  feedingGuidelinesRecipes: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      feedingGuidelinesRecipeVersions: PropTypes.arrayOf(PropTypes.object),
      level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    }),
  ).isRequired,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
