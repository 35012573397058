import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@web/atoms';
import {
  StyledTimelineMarker,
  TimelineMarkerTextContent,
  TimelineMarkerDot,
  StyledParagraph,
  SecondaryColorText,
} from './styles';

export const TimelineMarker = ({ body, heading, date, markerColor }) => {
  return (
    <StyledTimelineMarker>
      <TimelineMarkerDot variant={markerColor} />
      <TimelineMarkerTextContent>
        <SecondaryColorText>
          <Heading
            headingLevel="h3"
            typography={'subheading1'}
            headingText={date}
            position="center"
            backgroundColor={'neutral'}
          />
        </SecondaryColorText>

        <Heading
          headingLevel="h2"
          typography={'heading6'}
          headingText={heading}
          position="center"
          verticalPadding={1}
          backgroundColor={'neutral'}
        />

        <StyledParagraph>{body}</StyledParagraph>
      </TimelineMarkerTextContent>
    </StyledTimelineMarker>
  );
};

TimelineMarker.displayName = 'TimelineMarker';

TimelineMarker.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.node,
  date: PropTypes.string,
  markerColor: PropTypes.string,
};
