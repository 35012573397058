'use client';
import PropTypes from 'prop-types';
import useEmblaCarousel from 'embla-carousel-react';
import { ResponsiveImage } from '../ResponsiveImage';
import { useDotButton, usePrevNextButtons } from './functions';
import {
  StyledCarousel,
  SlideContainer,
  SlideViewport,
  SlideContent,
  DotContainer,
  PrevButton,
  NextButton,
  DotButton,
  Slide,
  BottomContainer,
  ImageDot,
} from './styles.js';

export const Carousel = ({
  paddingTop = 0,
  paddingBottom = 4,
  verticalPadding = 9,
  children,
  bottomElement,
  dotPadding,
  dotVariant = 'darkerFur',
  buttonPosition = '50',
  overlappingArrows = true,
  overlappingDots = false,
  showArrows = true,
  hideArrowMobile = false,
  showDots = true,
  itemsToShow,
  dotImages,
  onArrowClick,
  onDotClick,
  $dotColor = 'text.secondary',
  $arrowBgColor = 'text.primary',
  showArrowBorder = true,
  arrowButtonPadding = '10px',
  slideFullWidth = false,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);
  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <StyledCarousel
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      verticalPadding={verticalPadding}
    >
      <SlideViewport ref={emblaRef}>
        <SlideContainer>
          {children.map((child, index) => (
            <Slide
              key={index}
              itemsToShow={itemsToShow}
              fullWidth={slideFullWidth}
            >
              <SlideContent>{child}</SlideContent>
            </Slide>
          ))}
        </SlideContainer>
      </SlideViewport>
      {showArrows && (
        <>
          <PrevButton
            onClick={() => {
              onPrevButtonClick();
              onArrowClick?.();
            }}
            hideArrowMobile={hideArrowMobile}
            showArrowBorder={showArrowBorder}
            arrowButtonPadding={arrowButtonPadding}
            disabled={prevBtnDisabled}
            buttonPosition={buttonPosition}
            overlappingArrows={overlappingArrows}
            $arrowBgColor={$arrowBgColor}
          />
          <NextButton
            onClick={() => {
              onNextButtonClick();
              onArrowClick?.();
            }}
            hideArrowMobile={hideArrowMobile}
            showArrowBorder={showArrowBorder}
            arrowButtonPadding={arrowButtonPadding}
            disabled={nextBtnDisabled}
            buttonPosition={buttonPosition}
            overlappingArrows={overlappingArrows}
            $arrowBgColor={$arrowBgColor}
          />
        </>
      )}
      <BottomContainer
        dotPadding={dotPadding}
        overlappingDots={overlappingDots}
      >
        {bottomElement}
        {showDots && scrollSnaps.length > 1 && (
          <DotContainer>
            {dotImages
              ? scrollSnaps.map((_, index) => (
                  <ImageDot
                    key={index}
                    onClick={() => {
                      onDotButtonClick(index);
                      onDotClick?.();
                    }}
                    active={index === selectedIndex}
                  >
                    <ResponsiveImage {...dotImages[index]} />
                  </ImageDot>
                ))
              : scrollSnaps.map((_, index) => (
                  <DotButton
                    $dotColor={$dotColor}
                    key={index}
                    onClick={() => {
                      onDotButtonClick(index);
                      onDotClick?.();
                    }}
                    active={index === selectedIndex}
                    variant={dotVariant}
                  />
                ))}
          </DotContainer>
        )}
      </BottomContainer>
    </StyledCarousel>
  );
};

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  verticalPadding: PropTypes.number,
  children: PropTypes.node.isRequired,
  buttonPosition: PropTypes.oneOf(['15', '17', '30', '40', '45', '50']),
  dotVariant: PropTypes.string,
  overlappingArrows: PropTypes.bool,
  hideArrowsMobile: PropTypes.bool,
  showArrowBorder: PropTypes.bool,
  arrowButtonPadding: PropTypes.string,
  slideFullWidth: PropTypes.bool,
  overlappingDots: PropTypes.bool,
  bottomElement: PropTypes.node,
  dotPadding: PropTypes.number,
  itemsToShow: PropTypes.number,
  dotImages: PropTypes.array,
  onArrowClick: PropTypes.func,
  onDotClick: PropTypes.func,
};
