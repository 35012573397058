'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@web/atoms';
import { Carousel, CtaButton, ResponsiveImage } from '@web/molecules';
import { useReporter } from '../../reporter';
import { MuxVideo } from '../MuxVideo/index.js';
import { heroButtonClicked } from './events';
import {
  StyledSwipeHero,
  AssetsContainer,
  ContentContainer,
  Subheadline,
  Body,
  BodyImageContainer,
  MuxVideoWrapper,
} from './styles.js';

export const SwipeHero = ({
  assets,
  headline,
  headingLevel = 'h2',
  subheadline,
  bodyImage,
  body,
  cta,
  ctaColor,
  assetBgColor = 'hero.blueberry',
  contentBgColor = 'background.bone',
}) => {
  const reporter = useReporter();
  return (
    <StyledSwipeHero>
      <AssetsContainer assetBgColor={assetBgColor}>
        <Carousel
          itemsToShow={1}
          paddingBottom={0}
          verticalPadding={0}
          overlappingDots={true}
          hideArrowMobile={true}
          showArrowBorder={false}
          slideFullWidth={true}
          arrowButtonPadding="24px"
          $dotColor="text.lightest"
          $arrowBgColor="shadowGrey"
        >
          {assets.map((asset, key) =>
            asset.component === 'mux_video' ? (
              <MuxVideoWrapper key={key}>
                <MuxVideo {...asset} />
              </MuxVideoWrapper>
            ) : (
              <ResponsiveImage
                {...asset}
                key={key}
                style={{ width: 'auto', height: '100%' }}
              />
            ),
          )}
        </Carousel>
      </AssetsContainer>
      <ContentContainer contentBgColor={contentBgColor}>
        {headline && (
          <Heading
            backgroundColor={contentBgColor}
            headingLevel={headingLevel}
            typography={{ desktop: 'heading2', mobile: 'heading2' }}
            headingText={headline}
            position="center"
            verticalPadding={1}
          />
        )}
        {subheadline && <Subheadline>{subheadline}</Subheadline>}
        {bodyImage && (
          <BodyImageContainer>
            <ResponsiveImage {...bodyImage} />
          </BodyImageContainer>
        )}
        {body && <Body>{body}</Body>}
        {cta && (
          <CtaButton
            href={cta.target}
            variant={ctaColor}
            onClick={() =>
              reporter.tag(heroButtonClicked(cta.text, cta.target))
            }
          >
            {cta.text}
          </CtaButton>
        )}
      </ContentContainer>
    </StyledSwipeHero>
  );
};

SwipeHero.displayName = 'Swipe Hero';

SwipeHero.propTypes = {
  assets: PropTypes.array.isRequired,
  headline: PropTypes.string,
  headingLevel: PropTypes.string,
  subheadline: PropTypes.string,
  bodyImage: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
  }),
  body: PropTypes.node,
  cta: PropTypes.object,
  ctaColor: PropTypes.string,
  assetBgColor: PropTypes.string,
  contentBgColor: PropTypes.string,
};
