import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { Heading } from '@web/atoms';
import { ResponsiveImage } from '@web/molecules';
import {
  ButtonContainer,
  Subtext,
  TestimonialContainer,
  TestimonialsHighlightContainer,
  ButtonWrapper,
} from './styles';

export const TestimonialHighlight = ({
  testimonial,
  image,
  buttonText,
  buttonTarget,
  backgroundColor,
}) => {
  return (
    <TestimonialsHighlightContainer backgroundColor={backgroundColor}>
      {image?.src && <ResponsiveImage {...image} />}
      <TestimonialContainer>
        <Heading
          headingLevel="h5"
          headingText={testimonial.body}
          position="center"
          typography="heading4"
          verticalPadding={1}
        />
        <Subtext>{testimonial.name}</Subtext>
        <ButtonContainer>
          <ButtonWrapper backgroundColor={backgroundColor}>
            <LinkButton href={buttonTarget} horizontalPadding={10}>
              {buttonText}
            </LinkButton>
          </ButtonWrapper>
        </ButtonContainer>
      </TestimonialContainer>
    </TestimonialsHighlightContainer>
  );
};

TestimonialHighlight.displayName = 'TestimonialHighlight';

TestimonialHighlight.propTypes = {
  image: PropTypes.object,
  backgroundColor: PropTypes.string,
  buttonTarget: PropTypes.string.isRequired,
  testimonial: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
};
