import * as yup from 'yup';
import {
  HEADLINE,
  SUBHEADLINE,
  DOG_SELECTOR_LABEL,
  ADD_PUP_BTN_LABEL,
  ADD_PUP_BTN_URL,
  YOUR_PUP,
  DISCOUNT_AMOUNT,
  LEARN_MORE,
} from '../../testing/account';
import { schema as headerSchema } from './header';
import { schema as footerSchema } from './footer';
import { addonModalSchema } from './addon_modal';
import { responsivePopupModal } from './responsive_popup_modal';

export const schema = yup
  .object({
    header: headerSchema,
    footer: footerSchema,
    headline: yup.string().required(),
    subheadline: yup.string().required(),
    dogSelectorLabel: yup.string().required(),
    addPupBtnLabel: yup.string().required(),
    addPupBtnUrl: yup.string().required(),
    yourPup: yup.object().required(),
    discountAmount: yup.number().required(),
    learnMoreBtnLabel: yup.string().required(),
    learnMoreHeading: yup.string().required(),
    learnMoreBody: yup.string().required(),
    learnMoreHref: yup.string().required(),
    learnMoreImg: yup.object().required(),
    lowerContent: yup.array(yup.object()).required(),
    seoDescription: yup.string(),
    seoKeywords: yup.string(),
    seoTitle: yup.string(),
    socialDescription: yup.string(),
    socialImage: yup.object(),
    socialTitle: yup.string(),
    taggingPrefix: yup.string(),
    recommendedAddons: yup.array(addonModalSchema).required(),
    modals: yup.array(addonModalSchema).required(),
    portionSection: responsivePopupModal,
    appCtaBanner: responsivePopupModal,
  })
  .transform(value => ({
    header: value.header,
    footer: value.footer,
    headline: HEADLINE,
    subheadline: SUBHEADLINE,
    dogSelectorLabel: DOG_SELECTOR_LABEL,
    addPupBtnLabel: ADD_PUP_BTN_LABEL,
    addPupBtnUrl: ADD_PUP_BTN_URL,
    yourPup: YOUR_PUP,
    discountAmount: DISCOUNT_AMOUNT,
    learnMoreBtnLabel: LEARN_MORE.btnLabel,
    learnMoreHeading: LEARN_MORE.heading,
    learnMoreBody: LEARN_MORE.body,
    learnMoreHref: LEARN_MORE.href,
    learnMoreImg: LEARN_MORE.img,
    lowerContent: value.lower_content,
    seoDescription: value.seo_description,
    seoKeywords: value.seo_keywords,
    seoTitle: value.seo_title,
    socialDescription: value.social_description,
    socialImage: value.social_image,
    socialTitle: value.social_title,
    taggingPrefix: value.tagging_prefix,
    recommendedAddons: value.recommended_addons,
    modals: value.modals,
    portionSection: value.portion_section,
    appCtaBanner: value.app_cta_banner,
  }));
