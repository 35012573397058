'use client';
import styled from 'styled-components';

export const StyledImageCardsList = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.colors.get(props.$fontColor)};
  padding: ${props => props.theme.spacing(6, 0, 12, 0)};
`;

export const ImageCardsListContainer = styled.div`
  display: ${props => (props.isCarousel ? 'none' : 'block')};
  @media ${props => props.theme.tablet} {
    display: flex;
    justify-content: center;
    padding: ${props => props.theme.spacing(0, 12)};
    grid-gap: ${props => props.theme.spacing(7)};
  }
  @media ${props => props.theme.laptop} {
    margin: 0 auto;
    max-width: 1280px;
  }
`;

export const CardWrapper = styled.div`
  flex: 1;
  flex-basis: 310px;
  margin-top: ${props => props.theme.spacing(12)};
  width: 100%;
  @media ${props => props.theme.tablet} {
    margin-top: ${props => props.theme.spacing(12)};
  }
`;

export const MobileImageCardsListContainer = styled.div`
  display: ${props => (props.isCarousel ? 'block' : 'none')};
  width: 100%;
  padding: ${props => props.theme.spacing(10, 2, 0)};
  @media ${props => props.theme.tablet} {
    display: none;
  }

  & ${CardWrapper} {
    margin-top: 0;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: ${props => props.theme.spacing(10)};
  ${props => props.theme.getFocusOutline(props.$bgColor)}
  @media ${props => props.theme.tablet} {
    margin-top: ${props => props.theme.spacing(12)};
  }
`;
