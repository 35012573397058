import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, CtaButton } from '@web/molecules';
import { Heading } from '@web/atoms';
import { useReporter } from '../../reporter';
import { EmailCapture } from '../EmailCaptureBar/EmailCapture';
import { ctaButtonClicked } from './events';
import {
  CalloutContainer,
  StyledHeadingWrapper,
  ImageContainer,
  LinkWrapper,
  InputWrapper,
  ButtonWrapper,
} from './styles';

export const SimpleCalloutPromo = ({
  heading,
  headingLevel,
  headingTypography = 'headingMastiff',
  image,
  cta = null,
  fontColor = 'text.primary',
  backgroundColor = 'neutral.bone',
  buttonVariant = '',
}) => {
  const reporter = useReporter();
  return (
    <CalloutContainer $bgColor={backgroundColor}>
      <StyledHeadingWrapper>
        <Heading
          position="center"
          headingLevel={headingLevel}
          typography={headingTypography}
          fontColor={fontColor}
          headingText={heading}
          backgroundColor={backgroundColor}
          verticalPadding={1}
        />
      </StyledHeadingWrapper>
      <ImageContainer>
        <ResponsiveImage {...image} />
      </ImageContainer>
      {cta && (
        <LinkWrapper>
          {cta.component === 'email_capture' ? (
            <InputWrapper>
              <EmailCapture {...cta} buttonVariant={buttonVariant} />
            </InputWrapper>
          ) : (
            <ButtonWrapper $bgColor={backgroundColor}>
              <CtaButton
                variant={buttonVariant}
                href={cta.target}
                onClick={() =>
                  reporter.tag(ctaButtonClicked(cta.text, heading))
                }
              >
                {cta.text}
              </CtaButton>
            </ButtonWrapper>
          )}
        </LinkWrapper>
      )}
    </CalloutContainer>
  );
};

SimpleCalloutPromo.propTypes = {
  heading: PropTypes.string.isRequired,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  headingTypography: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  cta: PropTypes.shape({
    component: PropTypes.string,
    text: PropTypes.string,
    target: PropTypes.string,
  }),
  buttonVariant: PropTypes.string,
  fontColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};
